import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Search from "../components/Search/Search";
import { useState, useEffect } from "react";
import {
  getAllPinedMessage,
  deletePinDocument,
  getAllPinnedDoc,
} from "../components/apiCall";
import KeycloakUserService from "../keycloak/KeycloakUserService";
import { useNavigate } from "react-router";
import { Card, Modal, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import {
  setPinnedSearches,
  storeOutline,
  storeFileLink,
  storeDocDetail,
  addPath,
  setToaster,
} from "../redux/actions";

import PinnedDocs from "../components/PinnedDocs/PinnedDocs";
import PinnedDocsHeader from "../components/PinnedDocs/PinnedDocsHeader";
import PinnedDocsPopup from "../components/PinnedDocs/PinnedDocsPopup";
const Dashboard = (props) => {
  const navigate = new useNavigate();
  const [loader, setLoader] = useState(false);
  const [popupOn, setPopupon] = useState(false);
  const [contentPopup, setContentpopup] = useState({});
  const [pinnedDocs, setPinnedDocs] = useState([]);

  props.addPath("/");
  function handleSessionOut() {
    if (KeycloakUserService.isTokenExpired()) {
      navigate("/");
      props.saveSearchWord("");
      localStorage.clear();
      window.location.reload(false);
      KeycloakUserService.doLogout();
    }
  }
  const handlepopupOn = (data) => {
    setContentpopup(data);
    setPopupon(true);
  };
  const handleRemove = (pinId) => {
    deletePinDocument(pinId)
      .then(() => {
        setPinnedDocs((result) =>
          result.filter((item) => item.pinId !== pinId)
        );
        props.setPinnedSearches(
          pinnedDocs.filter((item) => item.pinId !== pinId)
        );
        setPopupon(false);
      })
      .catch(() => {
        props.setToaster(true);
      });
  };
  const getPinneddocument = async () => {
    setLoader(true);
    let docfrommongo = [];
    let docfromsolr = [];
    handleSessionOut();
    try {
      const getAllPinedMessageRes = await getAllPinedMessage();
      console.log("getAllPinedMessageRes", getAllPinedMessageRes);
      setPinnedDocs(getAllPinedMessageRes.data.data);
      props.setPinnedSearches(getAllPinedMessageRes.data.data);
      setLoader(false);
      // const idArray = getAllPinedMessageRes.data.data.map(
      //   (item) => item.documentId
      // );
      // docfrommongo = getAllPinedMessageRes.data.data;
      // const query = {
      //   q: idArray,
      //   filter: [],
      // };
    //   if(idArray.length >0){
    //   const getAllPinnedDocRes = await getAllPinnedDoc(query);
    //   console.log("getAllPinnedDocRes", getAllPinnedDocRes);
    //   docfromsolr = getAllPinnedDocRes.data.response.docs;
    //   docfrommongo.map((data)=>{
    //     let pindata = docfromsolr.filter(obj => obj.id === data.documentId);
    //     data.searchResults = pindata[0];
    //   });
      
    //   docfrommongo = docfrommongo.filter(item => item.searchResults !== undefined);
    //   console.log("docs fitered with search result-----------------",docfrommongo)
    //   setPinnedDocs(docfrommongo);
    //   props.setPinnedSearches(docfrommongo);
    //   setLoader(false);
    // }else{
    //   setPinnedDocs([]);
    //   props.setPinnedSearches([]);
    //   setLoader(false);
    // }
    } catch (err) {
      props.setToaster(true);
    }

    // .then((res) => {
    //   console.log("Res from getAllPinedMessage", res);
    //   // setPinneddata(res.data.data);
    //   props.setPinnedSearches(res.data.response.docs);
    //   setPinnedDocs(res.data.response.docs);
    //   setLoader(false);
    // })
    // .catch(() => {
    //   props.setToaster(true);
    // });
  };
  useEffect(() => {
    //the GET API call will happen if there is no data in redux , since redux is getting updated manually after each pin or unpin.
    //This helps the app to improve performance by calling this GET API at the starting of the APP only.

    //props.pinnedSearches.length === 0 && getPinneddocument();
    getPinneddocument();
  }, []);

  // home page of the app
  return (
    <div
      id="backgroundColorPage1"
      className="h-screen  overflow-y-scroll md:overflow-hidden"
      style={{
        background: " linear-gradient(180deg, #F1F6FB 43.23%, #FBF8EE 100%);",
      }}
    >
      <Navbar />
      <div className="mt-10">
        <Search />
      </div>
      {/* displaying first four pinned searches on homepage */}
      {loader ? (
        <div className="pinnedSection" id="pinnedfrompage1">
          <div class="dot-loader">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      ) : (
        <div>
          <div
            key={Math.random()}
            className="pinnedSection"
            id="pinnedfrompage1"
          >
            <PinnedDocsHeader length={pinnedDocs.length} />
            <Card.Group itemsPerRow={4} className="cardgroup">
              {pinnedDocs?.slice(0, 4).map((data) => {
                return (
                  <>
                    <PinnedDocs data={data} handlepopupOn={handlepopupOn} />
                  </>
                );
              })}
            </Card.Group>
            {/* popup for unpin from all pinned document page */}
            {popupOn && (
              <Modal
                className="modal-upload-unpin"
                open={popupOn}
                onClose={() => setPopupon(false)}
                size="tiny"
              >
                <PinnedDocsPopup contentPopup={contentPopup?.searchResults} />

                <div className="feedback-btn-wrap">
                  <Button
                    className="secondaryButton"
                    onClick={() => {
                      setPopupon(false);
                    }}
                  >
                    No, Cancel
                  </Button>
                  <Button
                    className="primaryButton"
                    onClick={(e) => handleRemove(contentPopup?.pinId)}
                  >
                    Yes, Unpin
                  </Button>
                </div>
              </Modal>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    pinnedSearches: state.pinnedSearches,
  };
};

export default connect(mapStateToProps, {
  setPinnedSearches,
  storeDocDetail,
  storeFileLink,
  storeOutline,
  addPath,
  setToaster,
})(Dashboard);
