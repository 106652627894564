import React, { useState } from "react";
import push_pin from "../../assets/images/push_pin.svg";
import { useNavigate } from "react-router-dom";
import {
  storeDocDetail,
  storeOutline,
  storeFileLink,
} from "../../redux/actions";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { getSasUrl } from "../apiCall";

const IngResultDisplay = (props) => {
  const navigate = useNavigate();
  const [disableButton1, setDisableButton1] = useState(false);
  const [disableButton2, setDisableButton2] = useState(false);
  const [loader, setLoader] = useState(false);

  function categoryList(categoryArray) {
    return categoryArray.join(", ");
  }

  const handlePdfView = (linkpdf, dualDoc) => {
    setLoader(true);
    getSasUrl({
      url: linkpdf,
      directory: props.result.root.docs[0].directory,
    })
      .then((res) => {
        setLoader(false);
        !dualDoc && window.open(res.data.convertedUrl, "_blank");
      })
      .catch((err) => {
        setLoader(false);
      });
  };
  const toViewDoc = (
    result,
    id,
    rootId,
    title,
    file_link,
    outline,
    equipment,
    type,
    fileName,
    meta,
    metaArray,
    exactDataMetaArray,
    dual
  ) => {
    props.storeOutline(JSON.stringify(outline));
    getSasUrl({
      url: file_link,
      directory: props.result.root.docs[0].directory,
    }).then((res) => {
      props.storeFileLink(res.data.convertedUrl);
      props.storeDocDetail({
        result: result,
        id: id,
        rootId: rootId,
        title: title,
        file_link: res.data.convertedUrl,
        equipment: equipment,
        type: type,
        fileName: fileName,
        meta: meta,
        metaArray: metaArray,
        exactDataMetaArray: exactDataMetaArray,
        dual: dual,
      });
      navigate("/searchResultDetail");
    });
  };
  const pinButtonClick = (result) => {
    if (props.pinnedDocs.length < 10) {
      props.pinTheDoc(result);
      setDisableButton1(true);
      setDisableButton2(false);
    } else {
      alert(
        "You have reached the maximum limit of pinned documents. Please unpin some documents before pinning new ones"
      );
    }
  };

  const unPinButtonClick = (pinId) => {
    setDisableButton1(false);
    setDisableButton2(true);
    props.unpindoc(pinId);
  };
  return (
    <div
      className="border shadow-md rounded-md ml-14 mr-14 mt-6"
      key={props.result.id}
    >
      {console.log("pindocs view", props.pinnedDocs)}
      <div className="p-5 border-b-2">
        <div className="flex font-semibold text-lg mb-4 titleandpin">
          {/* <div className="xauth-tag">
            <a class="ui orange  ribbon label">ingested</a>
          </div> */}
          <div className="overflow-hidden overflow-ellipsis w-[100%]">
            {props.result.root.docs[0].title}
          </div>
          <div>
            <br />
          </div>
          {props.pinnedDocs?.find(
            (item) => item.searchResults.root.docs[0].title[0] === props.result.root.docs[0].title[0]
          ) ? (
            <div className="pinImage">
              <button
                className="ml-auto w-8 h-8   "
                disabled={disableButton2 || props.apiCallInProgress}
                onClick={() =>
                  unPinButtonClick(
                    props.pinnedDocs?.find(
                      (item) => item.searchResults.root.docs[0].title[0] === props.result.root.docs[0].title[0]
                    ).pinId
                  )
                }
              >
                <img
                  className="h-7 m-[2px] rounded-full bg-[#1f97dc]  right-[4em] md:right-[26.5em]"
                  src={push_pin}
                  alt="push pin logo"
                />
              </button>
              {disableButton2 && (
                <div className="ing-loader-pin">
                  <Loader active inline />
                </div>
              )}
            </div>
          ) : (
            <div className="pinImage ">
              <button
                className="ml-auto w-8 h-8    unpindoc"
                disabled={disableButton1 || props.apiCallInProgress}
                onClick={() => pinButtonClick(props.result)}
              >
                <img
                  className="h-7 m-[2px]  rotate-45 rounded-full bg-gray-200  right-[4em]  md:right-[26.5em]  "
                  src={push_pin}
                  alt="push pin logo"
                />
              </button>
              {disableButton1 && (
                <div className="ing-loader-pin">
                  <Loader active inline />
                </div>
              )}
            </div>
          )}
        </div>
        <div>
          <div className="flex">
            {/* <div className="p-1 pl-4 pt-3 md:pt-1 pr-2  md:pr-4 rounded-3xl bg-[#FDF0E4] w-fit border-2 text-[#f48728] border-[#f48728] text-[9px] md:text-sm">
              Category:
              {categoryList(
                props.result.root.docs[0].equipment
                  ? props.result.root.docs[0].equipment
                  : []
              )}
            </div> */}
            <div className="ml-3 p-1 pl-4 pr-0 md:pr-4 rounded-3xl bg-[#FDF0E4] w-fit border-2 text-[#f48728] border-[#f48728] text-[9px] md:text-sm">
              Knowledge Source: {getType(props.result.root.docs[0].type)}
            </div>
          </div>
          {/* <div className="mt-3 p-1 pl-4 pr-4 rounded-3xl bg-blue-100 w-fit border-2 text-[#0971CE] border-[#0971CE] text-[9px] md:text-sm flex align-middle">
            <span className="material-symbols-outlined text-[20px]">link</span>
            <span className="ml-3">
              Original file:{" "}
              {loader ? (
                <Loader size="small" active inline />
              ) : (
                <span
                  className={`${
                    props.result.root.docs[0].dual
                      ? ""
                      : "underline cursor-pointer"
                  } `}
                  onClick={(e) =>
                    handlePdfView(
                      props.result.root.docs[0].file_link,
                      props.result.root.docs[0].dual
                    )
                  }
                >
                  {props.result.root.docs[0].dual
                    ? "NO FILE AVAILABLE"
                    : props.result.root.docs[0].file_name}
                </span>
              )}
            </span>
          </div> */}
        </div>
      </div>
      <div
            className="font-bold"
            style={{
              fontFamily: "Roboto, sans-serif",
              marginLeft: '15px',
              marginTop: '10px'
            }}
          >
            Summary
          </div> 
      <div className="p-5" style={{display:"flex", justifyContent:"flex-end"}}>
        {/* <div
          // onClick={() =>
          //   toViewDoc(
          //     props.result,
          //     props.result.id,
          //     props.result.root?.docs[0].id,
          //     props.result.root?.docs[0].title,
          //     props.result.root?.docs[0].file_link,
          //     props.result.root?.docs[0].outline,
          //     props.result.root?.docs[0].equipment,
          //     props.result.root?.docs[0].type,
          //     props.result.root?.docs[0].file_name,
          //     props.result.meta,
          //     props.result.root?.docs[0].dual
          //   )
          // }
        > */}
          {/* <div
            className="font-bold mb-3"
            style={{
              fontFamily: "Roboto, sans-serif",
            }}
          >
            Summary
          </div> */}

          {/* <div
            className="text-sm"
            style={{ fontFamily: "Inter" }}
            dangerouslySetInnerHTML={{
              __html: props.getFirstTwoSentence(
                props.result?.highlight
                  ? props.result?.highlight[0]
                  : props.result?.search_content
                  ? props.result?.search_content[0]
                  : "No Summary"
              ),
            }}
          ></div> */}
          <div className="text-m"
            style={{ fontFamily: "Inter" }}>
            <span>Lorem ipsum dolor sit amet. Aut <b>consequatur laboriosam</b> Sit modi vel iste quia. <i>Et consequatur voluptatem ex beatae</i> corrupti Qui dolores ut quas consequatur non eaque quaerat ab molestiae suscipit. Id dolore nulla est minima dignissimoset consequatur ut rerum voluptatem.

</span>
          </div>
          <button 
           className="bg-[#0971CE] text-white font-bold py-2 px-8 rounded"
           style={{ cursor: "pointer", width: '250px' }}
           onClick={() =>
            toViewDoc(
              props.result,
              props.result.id,
              props.result.root?.docs[0].id,
              props.result.root?.docs[0].title,
              props.result.root?.docs[0].file_link,
              props.result.root?.docs[0].outline,
              props.result.root?.docs[0].equipment,
              props.result.root?.docs[0].type,
              props.result.root?.docs[0].file_name,
              props.result.meta,
              props.result.metaArray,
              props.result.exactDataMetaArray,
              props.result.root?.docs[0].dual
            )
          } > Click to View</button>
        {/* </div> */}
      </div>
    </div>
  );
};
const getType = (type) => {
  if (type === "QUICK GUIDE") {
    return "Setup Instruction";
  } else if (type === "REFERENCE GUIDE") {
    return "Operating Procedure";
  } else if (type === "SETUP INSTRUCTION") {
    return "Setup Instruction";
  } else if (type === "OPERATING PROCEDURES") {
    return "Operating Procedure";
  } else if (type === "PROMOTIONAL MATERIAL") {
    return "Promotional Material";
  } else if (type === "TECHNICAL NOTE") {
    return "Techical Note";
  } else {
    return type;
  }
};
const mapStateToProps = (state) => {
  return {
    searchedWord: state.searchedWord,
    pinnedSearches: state.pinnedSearches,
    docDetail: state.docDetail,
  };
};

export default connect(mapStateToProps, {
  storeDocDetail,
  storeOutline,
  storeFileLink,
})(IngResultDisplay);

