import React, { useEffect, useState } from "react";
import Spinner from "./Spinner";

import {
  PdfLoader,
  PdfHighlighter,
  Highlight,
  Popup,
  AreaHighlight,
} from "react-pdf-highlighter";
import { connect } from "react-redux";
import Sidebar from "./Sidebar";

const PdfHighlighterPage = (props) => {
  let outLine = JSON.parse(props.outLineRedux);
  const HighlightPopup = null;
  let highlightText = [];
  let tableContents = [];
  //suppoed to be in onScrollCHange event removes since it is reseting the hash while scrolling causing a glitch.
  // const resetHash = () => {
  //   //document.location.hash = "";
  //   // let x = "r6bc3600e8192462aad86d9113077037a5";
  //   // document.location.hash = `highlight-${x}`;
  // };
  useEffect(() => {
    const handleBeforePrint = (event) => {
      showPrintAlert();
    };

    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "p") {
        if (props.noPrint) {
          event.preventDefault();
          showPrintAlert();
        }
      }
    };

    window.addEventListener("beforeprint", handleBeforePrint);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("beforeprint", handleBeforePrint);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  function showPrintAlert() {
    props.noPrint && alert("Can't print. Printing is disabled.");
  }

  const getRectValues = (element) => {
    let rectValues = [];
    if (element.meta.id) {
      rectValues.push({
        height: element.meta.height,
        width: element.meta.width,
        x1: element.meta.x1,
        x2: element.meta.x2,
        y1: element.meta.y1,
        y2: element.meta.y2,
        pageNumber: element.meta.pageNumber,
      });
    } else if (element.meta.length > 0) {
      for (let i = 0; i < element.meta.length; i++) {
        rectValues.push({
          height: element.meta[i].height,
          width: element.meta[i].width,
          x1: element.meta[i].x1,
          x2: element.meta[i].x2,
          y1: element.meta[i].y1,
          y2: element.meta[i].y2,
          pageNumber: element.meta[i].pageNumber,
        });
      }
    }
    return rectValues;
  };

  const getBoundingRect = (element) => {
    if (element.meta.id) {
      const value = {
        height: element.meta.height,
        width: element.meta.width,
        x1: element.meta.x1,
        x2: element.meta.x2,
        y1: element.meta.y1,
        y2: element.meta.y2,
        pageNumber: element.meta.pageNumber,
      };
      return value;
    } else if (element.meta.length > 0) {
      const value = {
        height: element.meta.height,
        width: element.meta.width,
        x1: element.meta.x1,
        x2: element.meta.x2,
        y1: element.meta.y1,
        y2: element.meta.y2,
        pageNumber: element.meta.pageNumber,
      };
      return value;
    }
  };
  console.log("props.docDetail", props.docDetail.meta);
  //needed tableContents to anchor , the data in it will only be anchored , so pushing the serach content for anchoring on the loading of the page
  if (props.docDetail.meta) {
    tableContents.push({
      comment: {
        emoji: "",
        text: "",
      },
      content: {
        text: "Summary from search display",
      },
      position: {
        boundingRect: {
          height: props.docDetail.meta.height,
          width: props.docDetail.meta.width,
          x1: props.docDetail.meta.x1,
          x2: props.docDetail.meta.x2,
          y1: props.docDetail.meta.y1,
          y2: props.docDetail.meta.y2,
          pageNumber: props.docDetail.meta.pageNumber,
        },
        rects: [
          {
            height: props.docDetail.meta.height,
            width: props.docDetail.meta.width,
            x1: props.docDetail.meta.x1,
            x2: props.docDetail.meta.x2,
            y1: props.docDetail.meta.y1,
            y2: props.docDetail.meta.y2,
            pageNumber: props.docDetail.meta.pageNumber,
          },
        ],
        pageNumber: props.docDetail.meta.pageNumber,
      },

      id: props.docDetail.meta.id.replace("/meta#", ""),
      isOutLine: true,
    });
  }

  if (outLine) {
    outLine.forEach((element) => {
      tableContents.push({
        comment: {
          emoji: "",
          text: "",
        },
        content: {
          text: element.heading_s,
        },
        position: {
          boundingRect: getBoundingRect(element),
          rects: getRectValues(element),
          pageNumber:
            element.meta.length > 0
              ? element.meta.pageNumber
              : element.meta.pageNumber,
        },
        id: element.id.replace("/outline#", ""),
        isOutLine: true,
      });
    });
    tableContents.sort(function (a, b) {
      return a.position.boundingRect.y1 - b.position.boundingRect.y1;
    });
    tableContents.sort(function (a, b) {
      return (
        a.position.boundingRect.pageNumber - b.position.boundingRect.pageNumber
      );
    });
  }

  let scrollViewerTo = (highlight) => {
    console.log(highlight);
  };

  const parseIdFromHash = () =>
    document.location.hash.slice("#highlight-".length);

  useEffect(() => {
    window.addEventListener("hashchange", scrollToHighlightFromHash, false);
  }, []);

  const scrollToHighlightFromHash = () => {
    const highlight = getHighlightById(parseIdFromHash());

    if (highlight) {
      scrollViewerTo(highlight);
    }
  };

  const getHighlightById = (id) => {
    let highlightContent = tableContents.find(
      (highlight) => highlight.id === id
    );
    if (!highlightContent) {
      highlightContent = tableContents.find((highlight) => highlight.id === id);
    }
    return highlightContent;
  };

  return (
    <div className="App" style={{ display: "flex", height: "100vh" }}>
      {/* <Sidebar highlights={highlightText} tableContents={tableContents} /> */}

      <div>
        <PdfLoader url={props.fileLinkRedux} beforeLoad={<Spinner />}>
          {(pdfDocument) => (
            <PdfHighlighter
              pdfDocument={pdfDocument}
              onScrollChange={() => {}}
              onSelectionFinished={() => {}}
              scrollRef={(scrollTo) => {
                scrollViewerTo = scrollTo;

                scrollToHighlightFromHash();
              }}
              highlightTransform={(
                highlight,
                index,
                setTip,
                hideTip,
                viewportToScaled,
                screenshot,
                isScrolledTo
              ) => {
                const isTextHighlight = !Boolean(
                  highlight.content && highlight.content.image
                );
                const component = isTextHighlight ? (
                  <Highlight
                    position={highlight.position}
                    comment={highlight.comment}
                  />
                ) : (
                  <AreaHighlight
                    // isScrolledTo={isScrolledTo}
                    highlight={highlight}

                    // onChange={(boundingRect) => {
                    //   updateHighlight(
                    //     2,
                    //     { boundingRect: viewportToScaled(boundingRect) },
                    //     { image: screenshot(boundingRect) }
                    //   );
                    // }}
                  />
                );

                return (
                  <Popup
                    popupContent={<HighlightPopup {...highlight} />}
                    // onMouseOver={(popupContent) =>
                    //   setTip(highlight, (highlight) => popupContent)
                    // }
                    // onMouseOut={hideTip}
                    onMouseOver={() => {}}
                    key={index}
                    children={component}
                  />
                );
              }}
              highlights={
                props.docDetail.meta
                  ? [
                      {
                        comment: {
                          emoji: "",
                          text: "",
                        },
                        content: {
                          text: "Summary from search display",
                        },
                        position: {
                          boundingRect: {
                            height: props.docDetail.meta.height,
                            width: props.docDetail.meta.width,
                            x1: props.docDetail.meta.x1,
                            x2: props.docDetail.meta.x2,
                            y1: props.docDetail.meta.y1,
                            y2: props.docDetail.meta.y2,
                            pageNumber: props.docDetail.meta.pageNumber,
                          },
                          rects: [
                            {
                              height: props.docDetail.meta.height,
                              width: props.docDetail.meta.width,
                              x1: props.docDetail.meta.x1,
                              x2: props.docDetail.meta.x2,
                              y1: props.docDetail.meta.y1,
                              y2: props.docDetail.meta.y2,
                              pageNumber: props.docDetail.meta.pageNumber,
                            },
                          ],
                          pageNumber: props.docDetail.meta.pageNumber,
                        },

                        id: props.docDetail.meta.id.replace("/meta#", ""),
                        isOutLine: true,
                      },
                    ]
                  : []
              }
            />
          )}
        </PdfLoader>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    fileLinkRedux: state.fileLinkRedux,
    outLineRedux: state.outLineRedux,
    docDetail: state.docDetail,
  };
};

export default connect(mapStateToProps)(PdfHighlighterPage);
