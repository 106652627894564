import React from "react";
import { Modal, Card } from "semantic-ui-react";
import push_pin from "../../assets/images/push_pin.svg";
import pdfimage from "../../assets/images/download.jpg";

const PinnedDocsPopup = ({ contentPopup }) => {
  return (
    <>
      <Modal.Header>
        <div className="unpinpopup">
          <div className="imageforunpin">
            <button className="ml-auto w-8 h-8 bg-gray-200 rounded-full rotate-45 imageunpin">
              <img className="h-7 m-[2px]" src={push_pin} alt="push pin logo" />
            </button>
          </div>
          <div>
            <h3> Unpin the document?</h3>
          </div>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div className="popupPin">
          <Card style={{ width: "400px" }}>
            <Card.Content className="cardheader">
              <div className="titleofcardpopup" style={{cursor:"default"}}>
                {contentPopup.procedureTitle
                  ? contentPopup.procedureTitle
                  : contentPopup.root?.docs[0]?.title}
              </div>
            </Card.Content>

            <Card.Content extra className="pdfimagewrapper">
              <div className="pdfimage">
                {contentPopup?.documentType ? (
                  <div className="xauth-tag-icon">
                    <a className="xauth-icon" id="unpin-popup">
                      Xauth
                    </a>
                  </div>
                ) : (
                  <img src={pdfimage} alt="no image" />
                )}
              </div>
              <div className="right-div">
                <div className="filetype">
                  {" "}
                  {contentPopup?.documentType
                    ? "Xauthor File"
                    : "Original File"}
                </div>
                <div className="linktoredirect">
                  {contentPopup.documentType
                    ? contentPopup.documentType
                    : contentPopup.root?.docs[0]?.file_name}
                </div>
              </div>
            </Card.Content>
          </Card>
        </div>
      </Modal.Content>
    </>
  );
};

export default PinnedDocsPopup;
