import React from "react";
import { connect } from "react-redux";
import { Dropdown, Form } from "semantic-ui-react";
// import DateFilter from './DateFilter.js';
import { useState } from "react";
import {
  saveSearchResult,
  saveSearchResultAuthDocs,
  setTotalDoc,
  setEquipment,
  setTypes,
  addfilter,
  setActivePage
} from "../../redux/actions";

function Filter(props) {
  const [appliedFilter, setAppliedFilter] = useState({
    equipment: "",
    type: "",
  });
  const [clearFilter, setClearFilter] = useState(false);
  const [type, setType] = useState("");
  const [equipment, setEquipment] = useState("");
  const saveFilter = (type, value) => {
    type === "type" && setType(value);
    type === "equipment" && setEquipment(value);
    setClearFilter(false);
    let newFilter = appliedFilter;
    newFilter[type] = value;
    setAppliedFilter(newFilter);
  };

  const handleApplyfilter = (e, clear) => {
    props.setActivePage(1);
    if (clear) {
      setType("");
      setEquipment("");
      setAppliedFilter({
        equipment: "",
        type: "",
      });
    }
    e.preventDefault();
    props.addfilter(
      JSON.stringify(
        clear
          ? {
              equipment: "",
              type: "",
            }
          : appliedFilter
      )
    );
    // props.rendering();
  };


  return (
    <>
      {/* filter colums for selection knowledge source and equipments with dropdown */}
      <Form className="flex flex-col">
        <div className="flex gap-3">
          {props.type.length > 0 ? (
            <div className="series-wrapper w-[200px]">
              <Form.Field className="w-[100%]">
                <Dropdown
                  placeholder="Source"
                  fluid
                  selection
                  options={props.type}
                  clearable={true}
                  multiple
                  search={true}
                  noResultsMessage= {null}
                  onChange={(e, data) => {
                    saveFilter("type", data.value);
                  }}
                  value={type}
                />
              </Form.Field>
            </div>
          ) : (
            ""
          )}
          <div className="series-wrapper w-[200px] pointer-events-none opacity-50">
              <Form.Field className="w-[100%]">
                <Dropdown
                  placeholder="Category"
                  fluid
                  selection
                  options={props.equipment}
                  clearable={true}
                  multiple
                  search={true}
                  onChange={(e, data) => {
                    saveFilter("equipment", data.value);
                  }}
                  value={equipment}
                />
              </Form.Field>
            </div>
        </div>
        <div className="mb-5 flex">
          <button
            className="p-2 pl-6 pr-6 ml-auto mt-7 text-sky-600 border-sky-600 border rounded-md curor-pointer text-bold font-inter"
            type="button"
            onClick={(e) => {
              handleApplyfilter(e, true);
            }}
          >
            Clear Filter
          </button>
          <button
            className="p-2 pl-6 pr-6 ml-auto mt-7 text-sky-600 border-sky-600 border rounded-md curor-pointer text-bold font-inter"
            type="button"
            onClick={(e) => {
              handleApplyfilter(e);
            }}
          >
            Apply
          </button>
        </div>
      </Form>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    appliedFilter: state.appliedFilter,
    searchedWord: state.searchedWord,
  };
};

export default connect(mapStateToProps, {
  saveSearchResult,
  saveSearchResultAuthDocs,
  setEquipment,
  setTotalDoc,
  setTypes,
  addfilter,
  setActivePage
})(Filter);
