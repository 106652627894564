import { connect } from "react-redux";
import { Worker, Viewer} from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { highlightPlugin, Trigger } from "@react-pdf-viewer/highlight";
import { Icon } from 'semantic-ui-react';
import {
  toolbarPlugin,
  ToolbarSlot,
  TransformToolbarSlot,
} from "@react-pdf-viewer/toolbar";
import { searchPlugin } from "@react-pdf-viewer/search";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { useEffect, useState } from "react";

const PdfView = (props) => {
  const [isDocumentLoaded, setDocumentLoaded] = useState(false);
  const [selectedOption, setSelectedOption] = useState('all');
  const [selectedAreaNumber, setSelectedAreaNumber] = useState(0);
  const [selectedExactAreaNumber, setSelectedExactAreaNumber] = useState(0);
  const [selectedSimilarAreaNumber, setSelectedSimilarAreaNumber] = useState(0);
  var screenWidth = window.screen.width;
  //   {
  //     pageIndex: 3,
  //     height: 1.55401,
  //     width: 28.1674,
  //     left: 27.5399,
  //     top: 15.0772,    },
  // const [areas, setAreas] = useState([
  //   {
  //     pageIndex: props.docDetail.meta.pageIndex-1,
  //     height: props.docDetail.meta.height,
  //     width: props.docDetail.meta.width,
  //     left: props.docDetail.meta.left,
  //     top: props.docDetail.meta.top,
  //   }
  // ]);
  const [areas, setAreas] = useState(props.docDetail.metaArray);
  const [sameTextAreas, setSameTextAreas] = useState(props.docDetail.exactDataMetaArray);
  const handleDocumentLoad = () => setDocumentLoaded(true);
  const highlightword = props.docDetail.result.search_content[0];

  //   const searchPluginInstance = searchPlugin();
  //   const { highlight } = searchPluginInstance;

  // const toolbarPluginInstance = toolbarPlugin();

  // to disable some toolbar options
  const renderToolbar = (Toolbar) => (
    <Toolbar>
      {(slots) => {
        const { ShowSearchPopover, Zoom, ZoomIn, ZoomOut } = slots;
        return (
          <div
            style={{
              alignItems: "center",
              display: "flex",
              width: "100%",
            }}
          >
            {/* <div style={{ padding: "0px 2px" }}>
              <ShowSearchPopover />
            </div> */}
            <div style={{ padding: "0px 2px" }}>
              <ZoomOut />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <Zoom />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <ZoomIn />
            </div>
          </div>
        );
      }}
    </Toolbar>
  );

  // const renderHighlights = (props) => {
  //   console.log("renderHighlights", props);
  //     return (
  //      <div>
  //         {areas.map((area, idx) => (
  //          <div
  //             key={idx}
  //             className="highlight-area"
  //             style={Object.assign(
  //               {},
  //               {
  //                 background: "yellow",
  //                 opacity: 0.4,
  //               },
  //               // Calculate the position
  //               // to make the highlight area displayed at the desired position
  //               // when users zoom or rotate the document
  //               props.getCssProperties(area, props.rotation)
  //             )}
  //           />
  //         ))}
  //       </div>
  //     );
    
  // };

  const renderHighlights = (props) => {
    // Assuming `areas` is an array of objects with `pageIndex` and other properties
    return (
      <div>
        { areas.map((area, idx) => {
          // Check if the pageIndex of the highlight area matches the current page index
          if (area.pageIndex === props.pageIndex && (selectedOption === 'similarResults' || selectedOption === 'all')) {
            return (
              <div
                key={idx}
                className="highlight-area"
                style={Object.assign(
                  {},
                  {
                    background: "yellow",
                    opacity: 0.4,
                  },
                  // Calculate the position
                  // to make the highlight area displayed at the desired position
                  // when users zoom or rotate the document
                  props.getCssProperties(area, props.rotation)
                )}
              />
            );
          }
  
          // If pageIndex does not match, return null (or an empty fragment)
          return null;
        })}
      </div>
    );
  };
  
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
    renderToolbar,
    toolbarPlugin: {
      //   searchPlugin: {
      //     keyword: [highlightword],
      //   },
      //   highlightPlugin: {
      //     renderHighlights,
      //     trigger: Trigger.None,
      //   },
    },
  });
  //const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;
  const { toolbarPluginInstance } = defaultLayoutPluginInstance;
  const { searchPluginInstance } = toolbarPluginInstance;
  const { highlight } = searchPluginInstance;
  useEffect(() => {
    if (isDocumentLoaded) {
      highlight(highlightword);
      if(areas.length >0){
        jumpToHighlightArea(areas[0]);
      }
      console.log("useeefect");
    }
  }, [isDocumentLoaded]);

  const incrementNumber = () => {
      setSelectedAreaNumber((prevNumber) => prevNumber + 1);
      jumpToHighlightArea(areas[selectedAreaNumber + 1]);
  };

  const decrementNumber = () => {
      jumpToHighlightArea(areas[selectedAreaNumber - 1]);
      setSelectedAreaNumber((prevNumber) => Math.max(0, prevNumber - 1));
  };

  const transform = (slot) => ({
    ...slot,
    Download: () => <></>,
    DownloadMenuItem: () => <></>,
    EnterFullScreen: () => <></>,
    EnterFullScreenMenuItem: () => <></>,
    SwitchTheme: () => <></>,
    SwitchThemeMenuItem: () => <></>,
    Print: () => <></>,
    Open: () => <></>,
  });
  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });
  const { jumpToHighlightArea } = highlightPluginInstance;
  
  return (
    <div className="rpv-core__viewer">
      {
        areas.length ? <div style={{ textAlign: 'right', marginTop: '15px', marginBottom: '15px', marginRight: '50px'  }}>
          <label>
            <span>{areas.length} Results found {`(${selectedAreaNumber+1} / ${areas.length})`}</span>
          </label>
          <button style={{marginLeft: '20px'}} disabled={selectedAreaNumber === 0} onClick={decrementNumber}>
            <Icon name="arrow up" />
          </button>
          <button  disabled={selectedAreaNumber === areas.length-1} onClick={incrementNumber}>
            <Icon name="arrow down" />
          </button>
        </div> : <></>
      }
      {/* <div
        style={{
          alignItems: "center",
          backgroundColor: "#eeeeee",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          display: "flex",
          padding: "0.25rem",
        }}
      >
        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
      </div> */}

      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <div style={{ height: "720px" }}>
          <Viewer
            fileUrl={props.fileLinkRedux}
            plugins={[defaultLayoutPluginInstance, highlightPluginInstance]}
            onDocumentLoad={handleDocumentLoad}
            defaultScale={1}
            initialPage={props.docDetail.meta.pageIndex-1}
          />
        </div>
      </Worker>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    fileLinkRedux: state.fileLinkRedux,
    outLineRedux: state.outLineRedux,
    docDetail: state.docDetail,
  };
};

export default connect(mapStateToProps)(PdfView);
