import React, { useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import ResultSearchBox from "../components/Search/ResultSearchBox";
import SearchResultBox from "../components/SearchResult/SearchResultBox";
import SearchResultOptions from "../components/SearchResult/SearchResultOptions";
import { connect } from "react-redux";
import { addPath } from "../redux/actions";
import { Loader } from "semantic-ui-react";
import QAengine from "../components/QAengineBox/QAengine";

const SearchResultPage = (props) => {
  const [loading, setLoading] = useState(false);
  const [featureFlag, setFeatureFlag] = useState(props.featureFlag);
  props.addPath("/searchResult");
  const ableLoading = (value) => {
    setLoading(value);
  };

  // after searching any thing this page will be displayed
  return (
    <div>
      <Navbar />
      <div className="resultboxinresult">
        <ResultSearchBox ableLoading={ableLoading} />
      </div>

      <>
        <div className="flex bg-[#FAFAFA] flex-col-reverse md:flex-row">
          {loading ? (
            <div
              className={`border shadow-md rounded-md ml-14 mr-14 mt-5 w-[27em] ${
                featureFlag["Q&A engine"] ? "md:w-[58.34em]" : "md:w-[83.34em]"
              } `}
            >
              <div className="loader">
                <Loader active inline />
              </div>
            </div>
          ) : (
            <div className="w-[97%]">
              <SearchResultOptions />
              <SearchResultBox />
            </div>
          )}
          <div>
            <QAengine />
          </div>
        </div>
      </>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    searchResult: state.searchResult,
    searchedWord: state.searchedWord,
    featureFlag: state.featureFlag,
  };
};

export default connect(mapStateToProps, { addPath })(SearchResultPage);
