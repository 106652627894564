import axios from "axios";
import KeycloakUserService from "../keycloak/KeycloakUserService";

const SEARCH = "/search";
const PIN_DOCUMENT = "/pindocument";
const FEEDBACK = "/feedback";
const FEATURE_FLAG = process.env.REACT_APP_FEATURE_FLAG_URL;
const QA_SYSTEM = process.env.REACT_APP_QNA_URL;
const FEEDBACK_COMMENT = "/feedback-comment";
const FEEDBACKCOMMENT = "/feedbackcomment/";
const RAISESAFEEDBACK = "/problem";
const SAS_URL = "/sas-url";
const ALL_PINNED_DOC = "/allpinneddocs";
const GET_PROCEDURE = "/procedure";


function keyCloakLoggedInOrToKenCheck() {
  if (KeycloakUserService.isTokenExpired()) {
    KeycloakUserService.doLogout();
  }

  if (KeycloakUserService.isLoggedIn()) {
    axios.defaults.params = {};
    axios.defaults.params["username"] = KeycloakUserService.getUsername();
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + KeycloakUserService.getToken();
  }
}

// api for searching all documents
export function searchApi(query) {
  keyCloakLoggedInOrToKenCheck();
  return axios.post(SEARCH, query);
}
// api for feature flag implementstion
export function featureFlagAPi() {
  const query = {
    client: process.env.REACT_APP_CLIENT,
    instance: process.env.REACT_APP_INSTANCE,
    product: process.env.REACT_APP_PRODUCT,
    project: process.env.REACT_APP_PROJECT,
  };
  return axios.get(FEATURE_FLAG, { params: query });
}

export function qaSystemApi(query) {
  return axios.post(QA_SYSTEM, query, {
    timeout: 90 * 1000, // Override the default timeout for this request
    headers: {
      "Content-Type": "application/json",
    },
  });
}
// api for adding feedback comments from form
export function feedbackComment(formData) {
  keyCloakLoggedInOrToKenCheck();
  return axios.post(FEEDBACK_COMMENT, formData);
}
// api for getting all feedback for a particular document
export function viewFeedbackComment(documentId, id) {
  keyCloakLoggedInOrToKenCheck();
  return axios.get(FEEDBACK_COMMENT + "/" + documentId + "/" + id);
}
// api for fetching all pinned document for a particular user
export function getAllPinedMessage() {
  keyCloakLoggedInOrToKenCheck();
  return axios.get(PIN_DOCUMENT);
}
export function getAllPinnedDoc(data) {
  console.log("log from getAllPinnedDoc", data);
  keyCloakLoggedInOrToKenCheck();
  return axios.post(ALL_PINNED_DOC, data);
}
//api for getting a pinned document using id
export function getPindatabyId(pinId) {
  return axios.get(PIN_DOCUMENT + "/" + pinId);
}

//api for adding a document as pinned
export function addPinDocument(data) {
  keyCloakLoggedInOrToKenCheck();
  return axios.post(PIN_DOCUMENT, data);
}
//api for removing pinned doc from mongodb
export function deletePinDocument(data) {
  keyCloakLoggedInOrToKenCheck();
  return axios.delete(PIN_DOCUMENT + "/" + data);
}

//api for getting count of feedback
export function feedbackCount(documentId) {
  keyCloakLoggedInOrToKenCheck();
  return axios.get(FEEDBACKCOMMENT + documentId + "/count");
}
export function feedbackHelpful(feedbackData) {
  keyCloakLoggedInOrToKenCheck();
  return axios.get(FEEDBACK, feedbackData);
}
export function raiseAFeedbackApi(raiseAFeedbackData) {
  keyCloakLoggedInOrToKenCheck();
  return axios.post(RAISESAFEEDBACK, raiseAFeedbackData);
}

export function getSasUrl(data) {
  keyCloakLoggedInOrToKenCheck();
  return axios.post(SAS_URL, data);
}

export function getProcedure(data){
  return axios.get(GET_PROCEDURE, data);
}
